import React, { useEffect, useState } from "react";
import Layout from "./Layout";
import { Link } from "gatsby";
import emoji from "../assets/images/404/emoji.svg";
import emojiBlurred from "../assets/images/404/emoji-blurred.svg";
import emojiBlurredAlt from "../assets/images/404/emoji-blurred-alt.svg";
import eyes from "../assets/images/404/eyes.svg";
import rocket from "../assets/images/404/rocket.svg";
import graph from "../assets/images/404/graph.svg";
import hand from "../assets/images/404/hand.svg";
import moneyBag from "../assets/images/404/money-bag.svg";
// @ts-ignore
import Bounce from "react-reveal/Bounce";
import { useTranslation } from "gatsby-plugin-react-i18next";

const NotFoundContent = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { t } = useTranslation("404");
  useEffect(() => {
    document.body.style.overflowX = "hidden";
    () => (document.body.style.overflowX = "auto");
    setTimeout(() => setIsLoaded(true), 100);
  }, []);
  return (
    <div className="page-not-found-wrapper">
      <Layout>
        <main className="page-not-found">
          <div className="container">
            <section className="section">
              <div className="section__banner">404</div>
              <h1 className="section__title">{t("title")}</h1>
              {/* <p className="section__text">
                We can't find the page you are looking for
              </p> */}
              <Link className="btn btn--secondary btn--sm" to="/">
                {t("buttonTitle")}
              </Link>
            </section>
            <div className="particles">
              <Bounce when={isLoaded} left delay={300} duration={2000}>
                <img
                  src={emoji}
                  alt="emoji"
                  className="particles__particle particles__particle--1"
                />
              </Bounce>
              <Bounce when={isLoaded} right delay={400} duration={2000}>
                <img
                  src={emojiBlurred}
                  alt="emoji-blurred"
                  className="particles__particle particles__particle--2"
                />
              </Bounce>
              <Bounce when={isLoaded} left delay={600} duration={2000}>
                <img
                  src={emojiBlurredAlt}
                  alt="emoji-blurred-alt"
                  className="particles__particle particles__particle--3"
                />
              </Bounce>
              <Bounce when={isLoaded} right delay={1300} duration={2000}>
                <img
                  src={eyes}
                  alt="eyes"
                  className="particles__particle particles__particle--4"
                />
              </Bounce>
              <Bounce when={isLoaded} right delay={1000} duration={2000}>
                <img
                  src={rocket}
                  alt="rocket"
                  className="particles__particle particles__particle--5"
                />
              </Bounce>
              <Bounce when={isLoaded} right delay={700} duration={2000}>
                <img
                  src={graph}
                  alt="graph"
                  className="particles__particle particles__particle--6"
                />
              </Bounce>
              <Bounce when={isLoaded} left delay={1200} duration={2000}>
                <img
                  src={hand}
                  alt="hand"
                  className="particles__particle particles__particle--7"
                />
              </Bounce>
              <Bounce when={isLoaded} left delay={900} duration={2000}>
                <img
                  src={moneyBag}
                  alt="money-bag"
                  className="particles__particle particles__particle--8"
                />
              </Bounce>
            </div>
          </div>
        </main>
      </Layout>
    </div>
  );
};

export default NotFoundContent;
